import { register } from "api";
import { IsUser } from "Redux/User/action";
import { Error } from "Responses/Response";
import { setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { SetToken } from 'Redux/Token/action';
import { WELCOME_PATH } from "utils/routes";
import { getAppBaseURL } from "utils/utility";

export const Register = (Data, Navigate) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const url = getAppBaseURL();
    const dataForm = {
      ...Data,
      redirect_url : `https://${url}/`
    }
    const { data, status } = await register(dataForm);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      setCookie("userDetail", JSON.stringify(data?.user));
      dispatch(IsUser(data?.user));
      dispatch(SetToken(data?.token));

      Navigate(WELCOME_PATH, { replace: true });

      // Amplitude
      if (data.user.id) {
        setUserId(data.user.id);
        track('Signed Up', {
          method: 'email'
        });
      }
    }
  } catch (error) {
    error?.response?.data?.email?.map((err) => Error(err));

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
