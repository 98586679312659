import * as amplitude from '@amplitude/analytics-browser';
import * as sessionReplay from '@amplitude/session-replay-browser';

import { isStaging, isProduction } from "../config";

// Production
const API_KEY_PROD = "6272f44173e51a2f25e1460a69037278";
const API_KEY_TEST = "273c494d61302deaa7c3b8ff6e4940a6";

// Staging/Development
const API_KEY = isProduction ? API_KEY_PROD : API_KEY_TEST;
const isEnabled = process.env.NODE_ENV !== "development" && (isStaging || isProduction);

export const init = async () => {
  if (isEnabled) {
    // 1. Init Amplitude
    try {
      await amplitude.init(API_KEY, {
        defaultTracking: true,
      }).promise;
    } catch (e) {
      console.error("🚀 Amplitude error", e);
    }

    // 2. Use amplitude's device/session IDs
    const deviceId = amplitude.getDeviceId();
    const sessionId = amplitude.getSessionId();

    // 3. Init Session Replay
    sessionReplay.init(API_KEY, {
      deviceId,
      sessionId,
      sampleRate: 1.0, // or 0.5, etc.
    });

    console.log("🚀 Amplitude initialized");
  }
};

export const track = (eventName: string, eventProperties: Record<string, any> = {}) => {
  if (isEnabled) {
    // Grab session replay properties
    const replayProps = sessionReplay.getSessionReplayProperties();

    // Merge
    const mergedProps = {
      ...eventProperties,
      ...replayProps,
    };

    // Track
    amplitude.track(eventName, mergedProps);
  }
};

export const setUserId = (userId?: string) => {
  if (isEnabled) {
    amplitude.setUserId(userId);
  }
};

export const reset = () => {
  if (isEnabled) {
    amplitude.reset();
  }
};

export default amplitude;
