import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

import DatePicker from "react-datepicker";

import { BREAKPOINTS } from "theme/mixins.js";
import useWindowSize from "utils/useWindowSize";

import "react-datepicker/dist/react-datepicker.css";
import './styled.module.scss';

const DatepickerInput = ({ dateRef, forwardRef, onCustomFocus, onCustomBlur, ...rest }) => {
  const { width } = useWindowSize();

  const mousedownTarget = useRef(null);

  useEffect(() => {
    const mousetracker = (event) => {
      mousedownTarget.current = event.target;
    };

    document.addEventListener('mousedown', mousetracker);

    return () => { // cleanup
      document.removeEventListener('mousedown', mousetracker);
    };
  }, []);

  const onFocusHandle = (e) => {
    dateRef?.current?.setOpen(true);
    dateRef?.current?.setFocus(true);
    rest?.onFocus(e);

    if (onCustomFocus)
      onCustomFocus();
  };

  const onBlurHandle = (e) => {
    const relatedTarget = e.relatedTarget || mousedownTarget.current;
    mousedownTarget.current = null;

    if (e.currentTarget.closest(".datepicker-wrapper").contains(relatedTarget) || (relatedTarget && relatedTarget.closest('.react-datepicker-popper'))) {
      e.currentTarget.focus();
    } else {
      dateRef?.current?.setOpen(false);
      dateRef?.current?.setBlur(true);
      rest?.onBlur(e);
      onCustomBlur(e);
    }
  };

  return (
    <input
      type="text"
      {...rest}
      readOnly={width <= BREAKPOINTS.xl}
      ref={forwardRef}
      onFocus={onFocusHandle}
      onBlur={onBlurHandle}
    />
  );
};

const CustomDatepicker = ({ onChange, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleChange = (date) => {
    onChange(date);
    handleClose();
  };

  return (
    // <div className="datepicker-wrapper">
      <DatePicker
        open={isOpen}
        portalId="portal-root"
        fixedHeight
        disabledKeyboardNavigation
        onChange={handleChange}
        placeholderText={"Set date"}
        dateFormat={"MM/dd/yyyy"}
        customInput={<DatepickerInput onCustomFocus={handleOpen} onCustomBlur={handleClose} />}
        onBlur={handleClose}
        onCalendarOpen={handleOpen}
        onSelect={handleClose}
        {...props}
      />
    // </div>
  );
};

export default CustomDatepicker;
