import Cookies from "js-cookie";
import { checkAuth } from "api";
import { setCookie } from "utils/StorageVariables";
import { setUserId } from 'utils/amplitude';
import { ActionTypes } from "./types";
import { IsUser } from "Redux/User/action";

async function getCheckAuthLocal() {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rawData = Cookies.get('token-local');

      if (!rawData || typeof rawData === "undefined") {
        resolve({
          data: {
            authenticated: false
          }
        });

        return;
      }

      const data = JSON.parse(rawData);

      resolve({
        data: {
          authenticated: true,
          ...data
        }
      });
    }, 150); // Simulate response time
  });
}

export const CheckAuth = (params) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING, payload: params });

    const { data: results } = process.env.NODE_ENV === 'development'
      ? await getCheckAuthLocal()
      : await checkAuth();

    if (results) {
      const data = results.token && results.user
        ? results
        : { token: '', user: {} }

      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data
      });

      if (results.user) {
        dispatch(IsUser(results.user));
        setUserId(results.user.id);
        setCookie("userDetail", JSON.stringify(results.user));
      }
    }
  } catch (error) {
    error?.response?.data?.detail?.map((err) => Error(err));
    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};

export const SetToken = token => async (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TOKEN,
    payload: { token }
  });
};

export const RemoveToken = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.REMOVE_TOKEN
  });
};
